/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'filetype-ppt': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M14 4.5V14a2 2 0 01-2 2h-1v-1h1a1 1 0 001-1V4.5h-2A1.5 1.5 0 019.5 3V1H4a1 1 0 00-1 1v9H2V2a2 2 0 012-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 00.161-.677q0-.375-.158-.677a1.2 1.2 0 00-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 01-.085.38.57.57 0 01-.238.241.8.8 0 01-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m2.817-1.333h-1.6v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474.162-.302.161-.677 0-.375-.158-.677a1.2 1.2 0 00-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 01-.085.38.57.57 0 01-.238.241.8.8 0 01-.375.082H4.15V12.48h.66q.327 0 .512.181.185.183.185.522m2.767-.67v3.336H7.48v-3.337H6.346v-.662h3.065v.662z"/>',
    },
});
